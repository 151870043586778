import React from 'react';
import { Typography } from 'amphitheatre';
import Layout from 'src/Layout';

import Container from 'src/Components/ContentContainer';

import * as Base from 'src/page-styles/base.styled';

const { Header } = Typography;

const PrivacyPolicy: React.FunctionComponent = () => {
  return (
    <Layout>
      <Container.Dark>
        <Base.MenuGap />
        <Base.Heading>Privacy Policy</Base.Heading>
        <Base.Text>
          <Base.Paragraph>
            This Privacy Policy sets out our commitment to protecting the
            privacy of your personal information that we collect through this
            website http://saberastro.com (Site) or directly from you. Please
            read this Privacy Policy carefully.
          </Base.Paragraph>
          <Base.Paragraph>
            Please contact us if you have any questions. You providing us with
            personal information indicates that you have had sufficient
            opportunity to access this Privacy Policy and that you have read and
            accepted it. If you do not wish to provide personal information to
            us, then you do not have to do so, however it may affect your use of
            this Site or any products and services offered on it.
          </Base.Paragraph>

          <Header level={3}>1. Type of personal information collected</Header>
          <Base.Paragraph>
            <b>Personal Information:</b> The type of personal information we
            collect may include is set out on our website.
          </Base.Paragraph>
          <Base.Paragraph>
            If we receive your personal information from third parties, we will
            protect it as set out in this Privacy Policy.
          </Base.Paragraph>

          <Header level={3}>
            2. Collection and use of personal information
          </Header>
          <Base.Paragraph>
            We collect and use the personal information for purposes including
            to contact and communicate with you, for internal record keeping and
            for marketing.
          </Base.Paragraph>

          <Header level={3}>3. Disclosure of personal information</Header>
          <Base.Paragraph>
            We may disclose personal information for purposes including to
            provide our products and services to you, and as required by law.
          </Base.Paragraph>
          <Base.Paragraph>
            Where we disclose your personal information to third parties for
            these purposes, we will request that the third party follow this
            Privacy Policy regarding handling of your personal information.
          </Base.Paragraph>

          <Header level={3}>
            4. Access to and correction of personal information
          </Header>
          <Base.Paragraph>
            <b>Access:</b> You may request details of personal information that
            we hold about you, in certain circumstances set out in the Privacy
            Act 1988 (Cth). An administrative fee may be payable for the
            provision of information. We may refuse to provide you with
            information that we hold about you, in certain circumstances set out
            in the Privacy Act.
          </Base.Paragraph>
          <Base.Paragraph>
            <b>Correction:</b> If you believe that any information we hold on
            you is inaccurate, out of date, incomplete, irrelevant or
            misleading, please contact us by email. We rely in part upon
            customers advising us when their personal information changes. We
            will respond to any request within a reasonable time. We will
            endeavor to promptly correct any information found to be inaccurate,
            incomplete or out of date.
          </Base.Paragraph>

          <Header level={3}>5. Complaints about breach</Header>
          <Base.Paragraph>
            If you believe that we have breached the Australian Privacy
            Principles and wish to make a complaint about that breach, please
            contact us on the email address below.
          </Base.Paragraph>

          <Header level={3}>6. Unsubscribe</Header>
          <Base.Paragraph>
            To unsubscribe from our e-mail database, or opt out of
            communications, please contact us at the details below.
          </Base.Paragraph>

          <Header level={3}>7. Storage and Security</Header>
          <Base.Paragraph>
            We are committed to ensuring that the information you provide is
            secure.
          </Base.Paragraph>

          <Header level={3}>
            For any questions or notice, please contact us at:
          </Header>
          <Base.Paragraph>
            Saber Astronautics Australia Pty. Ltd. and Saber Astronautics LLC.
            ABN: 80 133 482 881. Email:{' '}
            <a href="mailto:info@saberastro.com">info@saberastro.com</a>
          </Base.Paragraph>
        </Base.Text>
      </Container.Dark>
    </Layout>
  );
};

export default PrivacyPolicy;
